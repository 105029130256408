<template>
  <div class="page page-box page-padding">
    <div class="top-statistics">
      <div class="statistic-box">
        <img src="@/assets/all-class.png" alt="班级总数">
        <div>
          <span>{{topDate.ClassCount}}</span>
          <span>班级总数</span>
        </div>
      </div>
      <div class="statistic-box">
        <img src="@/assets/all-coach.png" alt="教练总数">
        <div>
          <span>{{topDate.TeacherCount}}</span>
          <span>教练总数</span>
        </div>
      </div>
      <div class="statistic-box">
        <img src="@/assets/all-student.png" alt="学员总数">
        <div>
          <span>{{topDate.StudentCount}}</span>
          <span>学员总数</span>
        </div>
      </div>
      <div class="statistic-box">
        <img src="@/assets/all-course.png" alt="已创课程">
        <div>
          <span>{{topDate.CourseCount}}</span>
          <span>已创课程</span>
        </div>
      </div>
    </div>
    <div class="main-header">
      <div class="left">已上课程统计</div>
      <div class="right">
        <span @click="changeDateArraySeven()" :class="{ 'selection-text': isActive === 1 }">近7天</span>
        <span @click="changeDateArrayThirty()" :class="{ 'selection-text': isActive === 2 }">近30天</span>
        <el-date-picker @change="changeOne" v-model="timesWeek" :clearable="false" type="week" :format="getList.scheduleStartDate + ' 至 ' + getList.scheduleEndDate" placeholder="选择周" :picker-options="onPicker" class="width-date" />
      </div>
    </div>
    <div class="main-content">
      <div>
        <span>{{ contentDate.CourseCount }}</span>
        <span>累计课程数</span>
      </div>
      <div>
        <span>{{ contentDate.CourseCount7 }}</span>
        <span>近7天已上课程数</span>
      </div>
      <div>
        <span>{{ contentDate.CourseCount30 }}</span>
        <span>近30天已上课程数</span>
      </div>
    </div>
    <div class="echart" id="mychart" :style="myChartStyle"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getTeacherIndex, getIndexCourseSum } from "@/service/home";
import { deepClone } from '@/utils/index';  // 引入深拷贝
import moment from 'moment';

const ListQuery = {
  StartDate: Date.now(),
  EndDate: Date.now(),
};
export default {
  data() {
    return {
      timesWeek: Date.now(),
      moment,
      listQuery: deepClone(ListQuery),
      onPicker: {
        firstDayOfWeek: 1,
      },
      getList: {
        scheduleStartDate: '', // 开始日期
        scheduleEndDate: '', // 结束日期
      },
      topDate: {}, // 顶部数据
      contentDate: {}, // main-content/图表 数据统计
      myChart: {},
      xData: [], //横坐标
      yData: [], //人数数据
      myChartStyle: { width: "100%" }, //图表样式
      CourseDayList: [], //根据时间查询的课程数据
      isActive: null
    };
  },
  mounted() {
    this.initEcharts();
    this.resetGetList();
  },
  created() {
    this.getTeacherIndex();
  },

  methods: {

    /**
     * 时间重置
     */
    async resetGetList() {
      // 86400000为一天时间戳  // 604800000为一周时间戳
      // 因为组件的限制，因为组件是从上周日到本周六，所有需要减去一天的时间戳
      //  写法大概为一周7天，减去当前已过天数，将天数转换成时间戳，最后再转换成日期
      let weekStartDay = Date.parse(new Date()) + ((7 - new Date(Date.parse(new Date())).getDay()) * 24 * 60 * 60 * 1000 + 86400000 - 604800000);
      let weekEndDay = Date.parse(new Date()) + ((7 - new Date(Date.parse(new Date())).getDay()) * 24 * 60 * 60 * 1000);
      this.getList.scheduleEndDate = this.fun(weekEndDay);
      this.getList.scheduleStartDate = this.fun(weekStartDay);
      // this.listQuery.StartDate = weekStartDay / 1000;
      this.listQuery.StartDate = parseInt(new Date(moment(weekStartDay).format('YYYY-MM-DD 00:00:00')).getTime() / 1000)
      this.listQuery.EndDate = weekEndDay / 1000;
      await this.getIndexCourseSum();
      this.changeDateArray();
    },

    //  转换时间格式
    fun(unixtimestamp) {
      const dt = new Date(unixtimestamp)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    // 当用户直接点击选择日期而不是点击进行以下操作
    // 此时val会默认传入星期一的时间戳
    async changeOne(val) {
      const timeStamp = val.getTime(); // 标准时间转为时间戳，毫秒级别
      let weekStartDay = timeStamp - 24 * 60 * 60 * 1000;
      let weekEndDay = timeStamp + 24 * 60 * 60 * 1000 * 5;

      this.getList.scheduleStartDate = this.fun(
        weekStartDay
      ); // 开始时间
      this.getList.scheduleEndDate = this.fun(
        weekEndDay
      ); // 结束时间
      this.listQuery.StartDate = weekStartDay / 1000;
      this.listQuery.EndDate = weekEndDay / 1000;
      await this.getIndexCourseSum();
      this.changeDateArray();
    },

    // 获取当前周一周日期数据
    changeDateArray() {
      let arr = [];
      for (let i = 0; i < 7; i++) {
        arr.push(moment(new Date(this.getList.scheduleStartDate).getTime() + i * 24 * 60 * 60 * 1000).format('MM-DD'));
      }
      this.xData = arr;
      this.initEchartData();
    },
    // 获取当天前7天数据
    async changeDateArraySeven() {
      this.isActive = 1;
      let arr = [];
      for (let i = 0; i < 7; i++) {
        arr.push(moment(new Date().getTime() - i * 24 * 60 * 60 * 1000).format('MM-DD'));
      }
      this.xData = arr.sort((a, b) => a > b ? 1 : a == b ? 0 : -1);

      this.listQuery.StartDate = parseInt(((new Date(new Date().toDateString()).getTime() - 6 * 24 * 60 * 60 * 1000)) / 1000);
      ;
      this.listQuery.EndDate = parseInt(new Date().getTime() / 1000);
      await this.getIndexCourseSum();
      this.initEchartData();
    },
    // 获取当天前30天数据
    async changeDateArrayThirty() {
      this.isActive = 2;
      let arr = [];
      for (let i = 0; i < 30; i++) {
        arr.push(moment(new Date().getTime() - i * 24 * 60 * 60 * 1000).format('MM-DD'));
      }
      this.listQuery.StartDate = parseInt(((new Date(new Date().toDateString()).getTime() - 29 * 24 * 60 * 60 * 1000)) / 1000);
      this.listQuery.EndDate = parseInt(new Date().getTime() / 1000);
      this.xData = arr.sort((a, b) => a > b ? 1 : a == b ? 0 : -1);
      await this.getIndexCourseSum();
      this.initEchartData();
    },

    /**
     * 获取首页顶部统计数据
     */

    async getTeacherIndex() {
      this.topDate = await getTeacherIndex();
    },

    /**
     * 获取已上课程统计数据
     */

    async getIndexCourseSum() {
      let res = await getIndexCourseSum(
        {
          ...this.listQuery,
        }
      );
      this.contentDate = res
      this.CourseDayList = res.CourseDayList.map(item => {
        let obj = {};
        obj.xDate = moment(new Date(item.Date * 1000)).format('MM-DD');
        obj.yDate = item.CourseCount;
        return obj;
      });
      this.xData = this.CourseDayList.map(item =>
        item.xDate
      )
      this.yData = this.CourseDayList.map(item =>
        item.yDate
      )
    },

    initEcharts() {

      this.myChart = echarts.init(document.getElementById("mychart"));
      // this.initEchartData();

      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    initEchartData() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' }
        },

        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: this.xData,
          axisLabel: {
            color: "#6A757E",
            fontSize: 12,
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          // max: 12,
          splitNumber: 4,
          position: 'left',
          axisLabel: {
            formatter: '{value} ',
            color: "#6A757E",
            fontSize: 12,
          },
        },
        grid: {
          x: 48,
          y: 24,
          x2: 20,
          y2: 61,
        },
        series: [
          {
            data: this.yData,
            type: "line",
            smooth: true,
            symbol: 'circle',  //设定为实心点 
            symbolSize: 2,
            color: "#8FCC80",     //设定实线点的色彩
            lineStyle: {
              width: 2,
              color: "#8FCC80", //设置实线的色彩
            },
          }
        ]
      };
      this.myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
.echart {
  background: #ffffff;
  min-width: 600px;
  height: 316px;
}
.top-statistics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .statistic-box {
    // width: 278px;
    width: 22%;
    height: 188px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    img {
      width: 80px;
      height: 80px;
      margin-right: 32px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      color: #6a757e;
      font-size: 14px;
      span:first-child {
        margin-bottom: 19px;
        font-weight: 500;
        color: #21293a;
        font-size: 30px;
      }
    }
  }
  .statistic-box:last-child {
    margin-right: 0;
  }
}
.main-header {
  padding: 0 24px;
  height: 52px;
  line-height: 52px;
  background: #ffffff;
  border-bottom: 1px solid #eaeeee;
  display: flex;
  justify-content: space-between;
  .left {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
  .right {
    span {
      color: #6a757e;
      font-size: 14px;
      font-weight: 400;
      margin-right: 24px;
      cursor: pointer;
    }
    // span:hover {
    //   color: #8fcc80;
    // }
    .selection-text {
      color: #8fcc80;
    }
    span:last-child {
      margin-right: 0;
    }
  }
}
.main-content {
  background: #ffffff;
  display: flex;
  align-items: center;
  div {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: (100%/3);
    span {
      color: #6a757e;
      font-size: 14px;
      font-weight: 400;
    }
    span:first-child {
      margin-top: 8px;
      font-weight: 500;
      color: #21293a;
      font-size: 30px;
    }
  }
}
.width-date {
  width: 210px;
  margin-left: 8px;
}
/deep/.el-input--prefix .el-input__inner {
  padding-right: 10px;
}
.page-main-box {
  margin-bottom: 24px;
  padding-bottom: 0;
}
</style>
